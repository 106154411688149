import { useTranslation } from '@pancakeswap/localization';
import { Button, Flex, Text } from '@pancakeswap/uikit';
import Container from 'components/Layout/Container';
import PageFullWidth from 'components/Layout/PageFullWidth';
import NextLink from 'next/link';
import styled from 'styled-components';

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <PageFullWidth>
        <CsContainer>
            <Flex width="100%" height="auto" flexDirection='column'>
                <Flex width="100%" height="auto" justifyContent='center'>
                  <Images
                      src="/images/hanagold/404.png?version=1.0"
                      alt='404 images'
                  />
                </Flex>
                <Text bold width='100%' textAlign="center" fontSize={["42px",,"64px"]}>{t("Page Not Found")}</Text>
                <Text width='100%' textAlign="center" color='textSubtle'  fontSize={["14px",,"24px"]}>{t("We’re sorry. The page you requested could no be found")}</Text>
                <Text width='100%' textAlign="center" color='textSubtle'  fontSize={["14px",,"24px"]}>{t("Please go back to the home page")}</Text>
                <Flex width="100%" justifyContent='center' mt="1.5rem">
                    <NextLink href="/" passHref>
                        <Button width="300px">
                            {t("GO HOME")}
                        </Button>
                    </NextLink>
                </Flex>
            </Flex>
        </CsContainer>
    </PageFullWidth>
  )
}

export default NotFound

const CsContainer = styled(Container)`
    width: 100%;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background:url('/images/hanagold/pagenotfound.png?version=1.1');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @media screen and (max-width:600px) {
        height: 75vh;
    }
`

const Images = styled.img`
    width: auto;
    height: 298px;
    @media screen and (max-width:600px) {
        width:100%;
        height: auto;
    }
`